import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { RestDataSnackBarComponent } from './rest-data-snack-bar.component';

@NgModule({
  declarations: [RestDataSnackBarComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatButtonModule,
  ],
})
export class RestDataSnackBarModule {}
