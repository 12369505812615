import { MatSnackBar, MatSnackBarDismiss, MatSnackBarRef } from '@angular/material/snack-bar';
import { merge } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { RestData, RestDataAction } from '../../rest-data';
import { RestDataSnackBarConfig } from './rest-data-snack-bar-config.model';
import { RestDataSnackBarComponent } from './rest-data-snack-bar.component';

/** Maximum amount of milliseconds that can be passed into setTimeout. */
const MAX_TIMEOUT = Math.pow(2, 31) - 1;

export class RestDataSnackBar<T> extends RestData<T> {
  /** after dismissed */
  private readonly afterDismissed$ = new ReplaySubject<MatSnackBarDismiss>();
  public afterDismissed(): Observable<MatSnackBarDismiss> {
    return this.afterDismissed$.asObservable();
  }

  private snackBarRef: MatSnackBarRef<RestDataSnackBarComponent>;
  private durationTimeoutId: any;

  constructor(public snackBar: MatSnackBar, public config?: RestDataSnackBarConfig) {
    super();

    if (!config) {
      this.config = new RestDataSnackBarConfig();
    } else {
      this.config = merge(new RestDataSnackBarConfig(), config);
    }
  }

  destroy() {
    this.dismiss();
    this.afterDismissed$.complete();
    super.destroy();
  }

  isLoading(value: boolean): RestData<T> {
    if (value && this.config.loadingState) {
      this.openSnackBar(0);
    }
    return super.isLoading(value);
  }

  setError(value: any): RestData<T> {
    if (value && this.config.errorState) {
      this.openSnackBar(this.config.errorState.duration);
    }
    return super.setError(value);
  }

  setValue(value: T): RestData<T> {
    if (this.config.successState) {
      this.openSnackBar(this.config.successState.duration);
    }
    return super.setValue(value);
  }

  success() {
    this.onAction$.next(RestDataAction.SUCCESS);
    this.dismiss();
  }

  retry() {
    this.dismiss().then(() => super.retry());
  }

  cancel() {
    this.onAction$.next(RestDataAction.CANCEL);
    this.dismiss().then(() => this.reset());
  }

  exec(data: () => Observable<T>) {
    this.dismiss().then(() => super.exec(data));
  }

  // --- HELPER(s) ---

  private dismiss(): Promise<void> {
    return new Promise((resolve) => {
      clearTimeout(this.durationTimeoutId);
      if (this.snackBarRef) {
        this.snackBarRef.afterDismissed().subscribe(() => {
          this.snackBarRef = undefined;
          resolve();
        });
        this.snackBar.dismiss();
      } else {
        resolve();
      }
    });
  }

  private dismissSnackBarAfter(duration: number) {
    clearTimeout(this.durationTimeoutId);
    if (this.snackBarRef && duration > 0) {
      this.durationTimeoutId = setTimeout(() => this.snackBar.dismiss(), Math.min(duration, MAX_TIMEOUT));
    }
  }

  private openSnackBar(duration = 5000) {
    // Open snackbar if not already opened
    if (!this.snackBarRef) {
      this.snackBarRef = this.snackBar.openFromComponent(RestDataSnackBarComponent, {
        panelClass: 'rest-data-snack-bar',
        horizontalPosition: this.config.horizontalPosition,
        verticalPosition: this.config.verticalPosition,
        duration: 0,
        data: this,
      });
      this.snackBarRef.afterDismissed().subscribe((value: MatSnackBarDismiss) => {
        this.afterDismissed$.next(value);
        clearTimeout(this.durationTimeoutId);
        this.snackBarRef = undefined;
      });
    }

    // Dismiss after x duration
    this.dismissSnackBarAfter(duration);
  }
}
