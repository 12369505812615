import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFrCh from '@angular/common/locales/fr-CH';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AuthModule } from '@badisi/ngx-auth/core';
import { OIDCAuthService } from '@badisi/ngx-auth/oidc';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RestDataSnackBarModule } from './common/components/rest-data';
import { AuthModule as APPAuthModule } from './common/core/auth/auth.module';
import { TranslateModule } from './common/core/translate';

export function authServiceInitializerFactory(authService: OIDCAuthService): () => Promise<any> {
  return () =>
    authService.init({
      authority: environment.authority,
      client_id: environment.clientId,
      response_type: environment.responseType,
      scope: 'm3-suppliers-portal-api openid profile',
      redirect_uri: window.location.origin + '/auth-callback',
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      availableLangs: ['fr-CH'],
      defaultLang: 'fr-CH',
      fallbackLang: 'fr-CH',
      prodMode: environment.production,
      reRenderOnLangChange: false,
    }),
    AuthModule.forRoot({
      serviceClass: OIDCAuthService,
      initializerFactory: authServiceInitializerFactory,
      autoLoginOn401: true,
    }),
    APPAuthModule.forRoot(),
    IonicModule.forRoot({
      mode: 'ios',
    }),
    HttpClientModule,
    AppRoutingModule,
    RestDataSnackBarModule,
    MatSnackBarModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fr-CH' },
    SplashScreen,
    StatusBar,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // Initialize moment
    // TODO: change it according to the language of the app
    registerLocaleData(localeFrCh, 'fr-CH');
    moment.tz.setDefault('Europe/Zurich');
    moment.locale('fr-ch');
  }
}
