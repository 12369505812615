<ng-container *transloco="let translate; scope: 'restData'; read: 'restData'">
  <ng-template #loadingTpl>
    <ng-container *ngIf="restData.config.loadingState">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px" fxFlex>
        <mat-progress-spinner mode="indeterminate" diameter="24" strokeWidth="2"> </mat-progress-spinner>
        <div class="message">
          {{ translate(restData.config.loadingState.message) }}
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #errorTpl>
    <ng-container *ngIf="restData.config.errorState">
      <div
        class="error-template"
        [class.column]="restData.config.errorState.showRetry && restData.config.errorState.showCancel"
        fxLayoutAlign="center center"
        fxFlex>
        <div class="message">
          {{ translate(restData.config.errorState.message) }}
        </div>
        <div fxLayout="row">
          <button
            mat-button
            *ngIf="restData.config.errorState.showRetry"
            aria-label="translate('restData.RETRY')"
            color="primary"
            (click)="restData.retry()">
            {{ translate('RETRY') }}
          </button>
          <button
            mat-button
            *ngIf="restData.config.errorState.showCancel"
            aria-label="translate('restData.CANCEL')"
            color="primary"
            (click)="restData.cancel()">
            {{ translate('CANCEL') }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #contentTpl>
    <ng-container *ngIf="restData.config.successState">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px" fxFlex>
        <div class="message">
          {{ translate(restData.config.successState.message) }}
        </div>
        <button
          mat-button
          *ngIf="restData.config.successState.showAction"
          aria-label="restData.config.successState.actionLabel"
          color="primary"
          (click)="restData.success()">
          {{ translate(restData.config.successState.actionLabel) }}
        </button>
      </div>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="!restData.loading; else loadingTpl">
    <ng-container *ngIf="restData.error; else contentTpl">
      <ng-container *ngTemplateOutlet="errorTpl"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>
