import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let obs = next.handle(request);
    if (!this.isAllowedRequest(request)) {
      obs = this.addAuthenticationToHeader(request, next);
    }
    return obs.pipe(
      catchError((error: HttpErrorResponse) => {
        this.catch401(error);
        return throwError(error);
      })
    );
  }

  // --- HELPER(s) ---

  private isAllowedRequest(request: HttpRequest<any>): boolean {
    return !request.url.includes(environment.api) && !request.url.includes(environment.media_api);
  }

  private addAuthenticationToHeader(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.authService.getAccesToken() != null) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getAccesToken()}`,
          'Accept-Language': 'fr',
        },
      });
    }
    return next.handle(request);
  }

  private catch401(error: HttpErrorResponse) {
    if (error.status === 401) {
      // *setTimeout* is used to fix a router issue where the navigation is cancelled
      setTimeout(() => this.router.navigateByUrl('login'));
    }
  }
}
