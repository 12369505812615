import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { RestDataErrorComponent } from '../error/rest-data-error.component';

@NgModule({
  exports: [RestDataErrorComponent],
  declarations: [RestDataErrorComponent],
  imports: [CommonModule, FlexLayoutModule, TranslocoModule, MatButtonModule, MatIconModule],
})
export class RestDataErrorModule {}
