import { Component, Input } from '@angular/core';
import { RestData } from '../../rest-data';

@Component({
  selector: 'rest-data-error',
  templateUrl: './rest-data-error.component.html',
  styleUrls: ['./rest-data-error.component.scss'],
})
export class RestDataErrorComponent {
  @Input()
  public useRetry = true;

  @Input()
  public useCancel = false;

  @Input()
  public restData: RestData<any>;
}
