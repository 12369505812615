export class UserProfile {
  constructor(
    public id: string,
    public idToken: string,
    public firstName: string,
    public lastName: string,
    public accessToken: string,
    public address: string,
    public phoneNumber: string,
    public email: string,
    public username: string,
    public refreshToken: string // public sourceId: number
  ) {}

  // ---- PUBLIC(s) ----

  get initials(): string {
    const getFirstLetter = (value: string): string => {
      return value && value.length > 0 ? value[0] : '';
    };
    return `${getFirstLetter(this.firstName)}${getFirstLetter(this.lastName)}`.toUpperCase();
  }

  get fullName(): string {
    if (this.firstName) {
      return `${this.firstName} ${this.lastName}`;
    } else {
      return this.lastName;
    }
  }
}
