import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { indexOf, some } from 'lodash';
import { RestDataSnackBar } from './rest-data-snack-bar';
import { RestDataSnackBarConfig } from './rest-data-snack-bar-config.model';

@Injectable({
  providedIn: 'root',
})
export class RestDataSnackbarService {
  private jobIds: string[] = [];

  constructor(private snackBar: MatSnackBar) {}

  createRestdata<T>(jobId: string, config?: RestDataSnackBarConfig): RestDataSnackBar<T> {
    if (!some(this.jobIds, (item: string) => item === jobId)) {
      const restData: RestDataSnackBar<T> = new RestDataSnackBar(this.snackBar, config);
      restData.onSuccess().subscribe(() => this.removeJobId(jobId));
      restData.onError().subscribe(() => {
        if (restData.error == 'unrecoverableError') {
          restData.config.errorState.message = 'ACCEPT_INCIDENT.UNRECOVERABLE_ERROR';
          restData.config.errorState.showRetry = false;
        }
        this.removeJobId(jobId);
      });
      this.jobIds.push(jobId);
      return restData;
    }
    return null;
  }

  private removeJobId(id: string) {
    const index = indexOf(this.jobIds, id);
    if (index !== -1) {
      this.jobIds.splice(index, 1);
    }
  }
}
