import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import { TranslocoConfig, TranslocoModule, TRANSLOCO_CONFIG, TRANSLOCO_LOADER } from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { TranslateLoader } from './translate.loader';

@NgModule({
  imports: [TranslocoMessageFormatModule.forRoot(), TranslocoModule],
})
export class TranslateModule {
  public static forRoot(config: TranslocoConfig): ModuleWithProviders {
    return {
      ngModule: TranslateModule,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          useValue: config,
        },
        {
          provide: TRANSLOCO_LOADER,
          useClass: TranslateLoader,
        },
      ],
    };
  }
}
