import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateLoader implements TranslocoLoader {
  constructor(private httpClient: HttpClient) {}

  getTranslation(value: string): Observable<Translation> {
    /*
     *  a) Transform lang so that we can scaffold everything by language
     *      (before)    'item1/item2/fr-CH'
     *      (after)     'fr-CH/item1/item2
     *
     *  b) Avoid to load a default file during app launch
     */
    const paths = value.split('/');
    const lang = paths.pop();
    if (paths.length > 0) {
      return this.httpClient.get<Translation>(`/assets/i18n/${lang}/${paths.join('/')}.json`);
    }
    return of({});
  }
}
