import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService as NgxAuthProvider } from '@badisi/ngx-auth/core';
import { StorageService } from '../../services/storage.service';
import { StaticData } from './models/static-data';
import { UserProfile } from './models/user-profile.model';

export const AFTER_LOGIN_REDIRECT_URL = 'AFTER_LOGIN_REDIRECT_URL';

@Injectable()
export class AuthService {
  public readonly ALREADY_KEYCLOACK_LOG = 'ALREADY_KEYCLOACK_LOG';
  constructor(private ngxAuthProvider: NgxAuthProvider, private ionicStorage: StorageService) {}

  async federatedSignIn(redirectUr: string) {
    await this.ngxAuthProvider.login(redirectUr);
  }

  async logout(): Promise<void> {
    await this.ngxAuthProvider.logout();
    this.ionicStorage
      .set(StaticData.LAST_USER_ID_CONNECTED, null)
      .then()
      .catch((error) => console.error(error));
  }

  getUserProfile(): UserProfile {
    const ngxUser = this.ngxAuthProvider.getUserProfile();
    if (ngxUser != null) {
      this.flagKeycloackLog();
    }
    const m3Id = this.getM3IdFromAccesToken(this.ngxAuthProvider.getAccessToken());
    const user: UserProfile = new UserProfile(
      m3Id,
      '',
      ngxUser.family_name,
      ngxUser.given_name,
      this.ngxAuthProvider.getAccessToken(),
      ngxUser.address,
      ngxUser.phone_number,
      ngxUser.email,
      ngxUser.preferred_username,
      ''
    );
    this.ionicStorage
      .set(StaticData.LAST_USER_ID_CONNECTED, user.id)
      .then()
      .catch((error) => console.error(error));
    // this.storeUser(user); //todo use for mobile
    return user;
  }

  getAccesToken(): string {
    return this.ngxAuthProvider.getAccessToken();
  }

  private flagKeycloackLog() {
    this.ionicStorage
      .set(this.ALREADY_KEYCLOACK_LOG, location.origin)
      .then()
      .catch((error) => console.error(error));
  }

  // ---- HELPER(S) ----

  // TODO store user for mobile app
  /* private async storeUser(user: UserProfile) {
    try {
      const userIdFromStorage = await this.customStorage.getCurrentUserId();
      if (userIdFromStorage && userIdFromStorage !== user.id) {
        console.warn(
          `The following current login is already saved in the storage : ${userIdFromStorage}`
        );
        await this.customStorage.clearByUsername(userIdFromStorage);
      }
      this.customStorage.saveCurrentUserId(user);
    } catch (error) {
      console.error(
        `Error occured during the saving of the currentUser in the storage : ${error}`
      );
    }
  }*/

  public getM3IdFromAccesToken(accesTokenString: string): string {
    const helper = new JwtHelperService();
    const accestoken = helper.decodeToken(accesTokenString);
    return accestoken['m3_id'];
  }
}
