import { Component, Input } from '@angular/core';
import { RestData } from '../../rest-data';

@Component({
  selector: 'rest-data-loading',
  templateUrl: './rest-data-loading.component.html',
})
export class RestDataLoadingComponent {
  @Input()
  public restData: RestData<any>;
}
