import { Observable, ReplaySubject, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export enum RestDataAction {
  RETRY = 'retry',
  CANCEL = 'cancel',
  SUCCESS = 'success',
}

export class RestData<T> {
  /** loading */
  private readonly isLoading$ = new ReplaySubject<boolean>();
  public onLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }
  public loading: boolean;

  /** error */
  private readonly onError$ = new ReplaySubject<any>();
  public onError(): Observable<any> {
    return this.onError$.asObservable();
  }
  public error: any;

  /** success */
  private readonly onSuccess$ = new ReplaySubject<T>();
  public isSuccess = false;
  public onSuccess(): Observable<T> {
    return this.onSuccess$.asObservable();
  }
  public value: T;

  /** action */
  protected readonly onAction$ = new ReplaySubject<RestDataAction>();
  public onAction(): Observable<RestDataAction> {
    return this.onAction$.asObservable();
  }

  private execSub$: Subscription;
  private data: Observable<T>;

  // --- PUBLIC API(s) ---

  isLoading(value: boolean): RestData<T> {
    if (value) {
      this.error = undefined;
    }
    this.loading = value;
    this.isLoading$.next(value);
    return this;
  }

  setError(value: any): RestData<T> {
    this.isLoading(false);
    this.error = value;
    this.onError$.next(value);
    return this;
  }

  setValue(value: T): RestData<T> {
    this.error = undefined;
    this.value = value;
    this.onSuccess$.next(value);
    this.isSuccess = true;
    return this;
  }

  retry() {
    if (this.data) {
      this.exec(() => this.data);
      this.onAction$.next(RestDataAction.RETRY);
    }
  }

  cancel() {
    this.onAction$.next(RestDataAction.CANCEL);
    this.reset();
  }

  exec(data: () => Observable<T>) {
    // Initialize
    this.data = data();
    this.reset();

    // Start observable
    this.isLoading(true);
    this.execSub$ = this.data
      .pipe(
        catchError((err) => {
          this.setError(err);
          return throwError(err);
        })
      )
      .subscribe((result: any) => {
        if (result?.status == 'pending') {
          this.setError('timeout');
        } else if (result?.status == 'error') {
          this.setError('unrecoverableError');
        } else {
          this.isLoading(false);
          this.setValue(result);
        }
      });
  }

  destroy() {
    this.isLoading$.complete();
    this.onError$.complete();
    this.onSuccess$.complete();
    this.onAction$.complete();
    if (this.execSub$) {
      this.execSub$.unsubscribe();
    }
  }

  // --- HELPER(s) ---

  protected reset() {
    this.loading = false;
    this.value = undefined;
    this.error = undefined;
    if (this.execSub$) {
      this.execSub$.unsubscribe();
    }
  }
}
