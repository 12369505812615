// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'https://api.dev.digital.m-3.com/supplier',
  // api: 'http://localhost:5001/api/m3/supplier',
  media_api: 'https://media.dev.digital.m-3.com',
  bucket_incident: 'm3-dev-media-incidents-private',
  clientId: 'm3-suppliers-portal-client',
  authority: 'https://login.dev.digital.m-3.com/realms/m3',
  responseType: 'code',
  m3PassDiscoveryLink: 'https://sso-migration.dev.digital.m-3.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
