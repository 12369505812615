import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RestDataLoadingComponent } from './rest-data-loading.component';

@NgModule({
  exports: [RestDataLoadingComponent],
  declarations: [RestDataLoadingComponent],
  imports: [CommonModule, FlexLayoutModule, MatProgressSpinnerModule],
})
export class RestDataLoadingModule {}
