<ng-container *transloco="let translate; scope: 'restData'">
  <ng-container *ngIf="restData.error">
    <mat-icon class="error-icon">
      <svg width="50" height="50" viewBox="0 0 60 60">
        <path
          fill="#858585"
          fill-rule="nonzero"
          d="M30 0a1 1 0 0 0-1 1c0 .679.333 1.018 1 1.018h1C45.979 2.546 58 14.896 58 30c0 15.439-12.561 28-28 28S2 45.439 2 30c0-7.46 2.9-14.479 8.166-19.764A.999.999 0 1 0 8.75 8.824C3.107 14.486 0 22.007 0 30c0 16.542 13.458 30 30 30s30-13.458 30-30S46.542 0 30 0z" />
        <path
          fill="currentColor"
          d="M30 40a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0-5c-1.104 0-2.29-1.059-2.29-2.366l-1.7-16.268C26.009 15.06 28.896 14 30 14s4 1.06 4 2.366l-1.688 16.268C32.312 33.94 31.104 35 30 35z" />
      </svg>
    </mat-icon>

    <div class="description">
      {{ translate(restData.error) }}
    </div>

    <div fxLayout="row">
      <button
        mat-button
        *ngIf="useRetry"
        aria-label="translate('restData.RETRY')"
        color="primary"
        (click)="restData.retry()">
        {{ translate('restData.RETRY') }}
      </button>
      <button
        mat-button
        *ngIf="useCancel"
        aria-label="translate('restData.CANCEL')"
        color="primary"
        (click)="restData.cancel()">
        {{ translate('restData.CANCEL') }}
      </button>
    </div>
  </ng-container>
</ng-container>
