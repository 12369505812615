import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AuthService } from '@badisi/ngx-auth/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AFTER_LOGIN_REDIRECT_URL } from './auth.service';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canLoad(): Observable<boolean> {
    return this.isAuthorized();
  }

  canActivate(): Observable<boolean> {
    return this.isAuthorized();
  }

  // ---- HELPER(s) ----

  private isAuthorized(): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      take(1),
      map((isAuthenticated: boolean): boolean => {
        if (!isAuthenticated) {
          sessionStorage.setItem(AFTER_LOGIN_REDIRECT_URL, `${location.pathname}${location.search}`);
          this.router.navigateByUrl('login');
          return false;
        }
        return isAuthenticated;
      })
    );
  }
}
