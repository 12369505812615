import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AFTER_LOGIN_REDIRECT_URL } from '../../core/auth';

// This component is used to redirect the user to the saved URL after login
@Component({
  selector: 'login-callback',
  template: '',
})
export class LoginCallbackComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    const savedRedirectUrl = sessionStorage.getItem(AFTER_LOGIN_REDIRECT_URL);

    if (savedRedirectUrl) {
      sessionStorage.removeItem(AFTER_LOGIN_REDIRECT_URL);
      this.router.navigateByUrl(savedRedirectUrl);
    } else {
      this.router.navigateByUrl('/home');
    }
  }
}
