import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

export class RestDataSnackBarConfig {
  horizontalPosition?: MatSnackBarHorizontalPosition = 'center';
  verticalPosition?: MatSnackBarVerticalPosition = 'bottom';

  loadingState?: {
    message: string;
  };

  errorState?: {
    duration?: number;
    showRetry?: boolean;
    showCancel?: boolean;
    message: string;
  };

  successState?: {
    duration?: number;
    showAction?: boolean;
    actionLabel?: string;
    message: string;
  };
}
