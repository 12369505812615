import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'rest-data-snack-bar',
  templateUrl: './rest-data-snack-bar.component.html',
  styleUrls: ['./rest-data-snack-bar.component.scss'],
})
export class RestDataSnackBarComponent {
  constructor(
    // should be of type RestDataSnackBar but implies circular dependencies
    @Inject(MAT_SNACK_BAR_DATA) public restData: any
  ) {}
}
